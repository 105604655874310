import React from "react";
import { useTheme } from "@emotion/react";

const EyeIcon = () => {
  const theme = useTheme();

  return (
    <svg viewBox="0 0 480 480">
      <path
        style={{ fill: theme.colors.primary }}
        d="m249.33,365.19c64.97-4.78,116.2-59,116.2-125.19s-51.23-120.41-116.2-125.19c95.52,3,179.48,51.81,230.67,125.19C480,107.45,372.55,0,240,0S0,107.45,0,240c51.19-73.38,135.15-122.19,230.67-125.19-64.97,4.78-116.2,59-116.2,125.19s51.23,120.41,116.2,125.19c-95.52-3-179.48-51.81-230.67-125.19h0c0,132.55,107.45,240,240,240s240-107.45,240-240h0c-51.19,73.38-135.15,122.19-230.67,125.19Zm-43.89-125.19c0-19.08,15.47-34.55,34.55-34.55s34.55,15.47,34.55,34.55-15.47,34.55-34.55,34.55-34.55-15.47-34.55-34.55Z"
      />
    </svg>
  );
};

export default EyeIcon;

module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Inmost®","short_name":"Inmost®","start_url":"/","background_color":"#fcfcfa","theme_color":"#b80808","orientation":"portrait","lang":"it","scope":"/","display":"standalone","icon":"src/images/icon.png","icon_options":{"purpose":"any maskable"},"shortcuts":[{"name":"Leggi","short_name":"Articoli","description":"View the list of podcasts you saved for later","url":"/risorse/articoli/","icons":[{"src":"/images/layers.png","sizes":"192x192"}]},{"name":"Ascolta","short_name":"Inmost® Talks","description":"View the list of podcasts you listen to","url":"/risorse/podcast/inmost-talks/","icons":[{"src":"/images/stereo.png","sizes":"192x192"}]}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"526c612f197c85fd3f2d8b0cef7d56c8"},
    },{
      plugin: require('../../packages/inmost-ui/gatsby-browser.js'),
      options: {"plugins":[],"mailchimpEndpoint":"https://matteoalbini.us4.list-manage.com/subscribe/post?u=21e43fba8ee3af3831d4034ce&amp;id=1bc710c67b&amp;f_id=001b2ce6f0"},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

import device from "./device";
import fonts from "./fonts";
import typography from "./typography";

const theme = {
  fonts: fonts,
  typography: typography,
  device: device,
};

export default theme;

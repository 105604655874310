import React from "react";
import { useTheme } from "@emotion/react";

const LayersIcon = () => {
  const theme = useTheme();

  return (
    <svg x="0px" y="0px" viewBox="0 0 300 300">
      <g style={{ fill: theme.colors.primary }}>
        <path
          d="M20.1,112.5C46,90.1,94.5,75,150,75s104,15.1,129.9,37.5c12.8-11,20.1-23.8,20.1-37.5c0-41.4-67.2-75-150-75
                    S0,33.6,0,75C0,88.7,7.3,101.5,20.1,112.5z"
        />
        <path
          d="M279.9,187.5C254,209.9,205.5,225,150,225S46,209.9,20.1,187.5C7.3,198.5,0,211.3,0,225c0,41.4,67.2,75,150,75
                    s150-33.6,150-75C300,211.3,292.7,198.5,279.9,187.5z"
        />
        <path
          d="M0,150c0,13.7,7.3,26.5,20.1,37.5C46,165.1,94.5,150,150,150c-55.5,0-104-15.1-129.9-37.5
                        C7.3,123.5,0,136.3,0,150z"
        />
        <path
          d="M279.9,112.5C254,134.9,205.5,150,150,150c55.5,0,104,15.1,129.9,37.5c12.8-11,20.1-23.8,20.1-37.5
                        S292.7,123.5,279.9,112.5z"
        />
      </g>
    </svg>
  );
};

export default LayersIcon;

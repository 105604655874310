import React from "react";
import { useTheme } from "@emotion/react";

const AtomIcon = () => {
  const theme = useTheme();

  return (
    <svg x="0px" y="0px" viewBox="0 0 300 300">
      <g style={{ fill: theme.colors.primary }}>
        <path
          d="M150,0C67.2,0,0,67.2,0,150s67.2,150,150,150s150-67.2,150-150S232.8,0,150,0z M256.1,256.1
            c-20.8,20.8-62.6,14.9-106.1-11.2c-43.5,26.1-85.3,31.9-106.1,11.2S29.1,193.5,55.1,150C29.1,106.5,23.2,64.7,43.9,43.9
            S106.5,29.1,150,55.1c43.5-26.1,85.3-31.9,106.1-11.2c20.8,20.8,14.9,62.6-11.2,106.1C270.9,193.5,276.8,235.3,256.1,256.1z"
        />
        <path
          d="M244.9,150c-10.7-17.9-24.8-36-41.8-53c-17.1-17.1-35.2-31.1-53-41.8C132.1,65.8,114,79.9,97,97
            s-31.1,35.2-41.8,53c10.7,17.9,24.8,36,41.8,53s35.2,31.1,53,41.8c17.9-10.7,36-24.8,53-41.8C220.1,186,234.2,167.9,244.9,150z"
        />
      </g>
    </svg>
  );
};

export default AtomIcon;

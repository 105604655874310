import React from "react";
import { useTheme } from "@emotion/react";

const HomeIcon = () => {
  const theme = useTheme();

  return (
    <svg x="0px" y="0px" viewBox="0 0 480 480">
      <g style={{ fill: theme.colors.primary }}>
        <polygon points="273.7,280 276.7,277.5 330.7,462.5 266.8,462.5" />
        <polygon points="258.9,288.2 262.6,286.9 254,479.3 193.6,458.7" />
        <polygon points="242.3,291.2 246.3,291.1 176,470.6 125.5,431.5" />
        <polygon points="225.6,288.7 229.4,289.9 105,437.1 69.8,383.8" />
        <polygon points="210.7,280.9 213.9,283.3 48.7,382.4 32.4,320.7" />
        <polygon points="199,268.8 201.3,272 12.9,312.6 17.5,248.8" />
        <polygon points="191.9,253.5 193,257.3 1.6,234.9 26.5,176.1" />
        <polygon points="190.1,236.7 189.9,240.6 16,157.7 58.5,110.1" />
        <polygon points="193.8,220.2 192.3,223.9 54.5,89.3 110.1,57.9" />
        <polygon points="202.6,205.9 200.1,208.9 113,37 175.8,25.2" />
        <polygon points="215.6,195.1 212.2,197.1 185.3,6.3 248.5,15.5" />
        <polygon points="231.4,189.1 227.5,189.9 263.6,0.7 320.5,29.7" />
        <polygon points="248.2,188.5 244.3,188 339.5,20.5 384,66.4" />
        <polygon points="264.4,193.4 260.8,191.7 405,63.8 432.3,121.6" />
        <polygon points="278.1,203.2 275.3,200.5 453,126 460.2,189.4" />
        <polygon points="287.9,217 286.1,213.4 478.3,200.3 464.7,262.7" />
        <polygon points="292.7,233.1 292.2,229.2 478.4,278.8 445.3,333.4" />
        <polygon points="292.1,250 292.9,246.1 453.1,353.1 404.2,394.1" />
        <polygon points="286.1,265.7 288.1,262.3 405.2,415.3 345.6,438.3" />
      </g>
    </svg>
  );
};

export default HomeIcon;

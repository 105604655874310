import React from "react";
import { useTheme } from "@emotion/react";

const AsteriskIcon = () => {
  const theme = useTheme();

  return (
    <svg x="0px" y="0px" viewBox="0 0 300 300">
      <path
        style={{ fill: theme.colors.primary }}
        d="M275.4,166.1l-114.1-16.3l115,7.4c13.8,0.9,25-10.8,23.6-24.6s-14.9-22.8-28.2-19.1l-110.8,31.5l108.1-40
                c12.9-4.8,18.5-20.1,11.5-32v0c-6.9-11.9-22.9-14.8-33.5-5.9L158.4,141l82.5-80.5c9.9-9.6,8.7-25.9-2.5-34h0
                c-11.2-8.1-27-4.2-33,8.2l-50.8,103.4l42.6-107.1c5.1-12.8-2.6-27.2-16.1-30h0c-13.5-2.9-26.3,7.2-26.8,20.9L150,137.3l-4.6-115.1
                C144.8,8.3,132-1.6,118.5,1.3h0c-13.5,2.9-21.1,17.2-16,30l42.9,107L94.3,34.9c-6.1-12.4-21.9-16.3-33.1-8.1s-12.3,24.3-2.4,34
                l82.7,80.2L52.9,67.4c-10.6-8.8-26.6-5.9-33.5,6l0,0c-6.9,12-1.3,27.2,11.6,32l108.2,39.7L28.3,113.9c-13.3-3.7-26.7,5.4-28.2,19.1
                v0c-1.4,13.7,9.9,25.4,23.6,24.5l115-7.7l-114,16.6c-13.7,2-22.2,15.8-17.9,28.9v0c4.3,13.1,19.4,19.2,31.6,12.8l101.9-53.8
                l-97.4,61.6c-11.7,7.4-13.9,23.5-4.6,33.7v0c9.2,10.2,25.5,9.7,34-1.2l71.2-90.6l-63.9,95.9c-7.7,11.5-3.1,27.1,9.5,32.7l0,0
                c12.6,5.6,27.2-1.5,30.6-14.9l28.2-111.7l-19.4,113.6c-2.3,13.6,8.2,26,22,26h0c13.8,0,24.3-12.5,21.9-26.1l-19.7-113.5l28.5,111.7
                c3.4,13.4,18,20.5,30.6,14.8s17.1-21.3,9.4-32.7l-64.2-95.7l71.4,90.4c8.6,10.8,24.8,11.3,34,1.1c9.2-10.3,7-26.4-4.7-33.7
                l-97.6-61.3l102,53.6c12.2,6.4,27.3,0.3,31.5-12.9v0C297.7,181.9,289,168.1,275.4,166.1z"
      />
    </svg>
  );
};

export default AsteriskIcon;
